<template>
  <div class="page-wrapper">
    <b-container class="container-fluid p-0">
      <div class="authentication-main">
        <b-row>
          <b-col>
            <div class="auth-innerright">
              <div class="authentication-box">
                <div class="text-center">
                  <img src="~/assets/images/logo.svg" alt="logo">
                </div>
                <b-card class="mt-4">
                  <router-view />
                </b-card>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'Session'
};
</script>
